import style from './Loader.module.css';

const Loader = () => {
  const icons = ['🚗','🚚','🛺','🚃','🛥','🚎','🚢','🚂'];
  const random = icons[Math.floor(Math.random() * icons.length)];
  return (
    <div className={style.wrapper}>
      <span className={style.text}>Loading...</span>
      <div className={style.loader}>{`${random}💨`}</div>
    </div>
  )
}

export default Loader;
