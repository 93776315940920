import style from './Home.module.css';
import logo from '../blogomoji.svg';

import { useNavigate } from "react-router-dom";

import Button from '../components/Button';

const Home = () => {
  const navigate = useNavigate();

  return (<>
      <main>
        <h1 className={style.logo}>
          <img src={logo} alt="Blogomoji"/>
        </h1>
        <section className="left">
          <h2>🎉 Welcome!</h2>
          <p>A simple, fun & creative way to capture and share the small events in your daily life.</p>
          <p>Drag and drop emojis from the bar at the bottom.</p>
        </section>
        <Button className="cta" label="Sign in →" onClick={() => navigate('/auth')} />
      </main>
    </>)
}

export default Home;
