
export const USER = `query user($id: ID, $username: String){
  user(id: $id, username: $username) {
    id
    username
    emoji
    bio
    count {
      posts
      followers
      following
    }
    posts {
      id
      emoji
      text
      date
      privacy
    }
  }
}`

export const POSTS = `query posts($start: DateTime, $end: DateTime){
  posts(start: $start, end: $end) {
    id
    emoji
    text
    date
    }
}`


export const ALIVE = `query alive {
  alive {
    id
    username
    emoji
    count
    token
  }
}`

export const AUTH = `query auth($phone: String!, $code: String) {
  auth(phone: $phone, code: $code) {
    id
    username
    emoji
    phone
    token
  }
}`

export const UPDATE_USER = `mutation updateUser($input: UserInput!) {
  updateUser(input: $input) {
    id
    username
    emoji
    count
    token
  }
}`

export const UPDATE_POST = `mutation updatePost($input: PostInput!) {
  updatePost(input: $input) {
    id
    emoji
    text
    date
  }
}`

export const DELETE_POST = `mutation deletePost($id: ID!) {
  deletePost(id: $id) {
    id
  }
}`
