import style from './Calendar.module.css';

import { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { useQuery, useMutation } from 'graphql-hooks'

import Day from './Day';
import ToolBar from '../components/ToolBar';
import Loader from '../components/Loader';
import Button from '../components/Button';

import { today, range, make, hydrate } from '../utils/Engine';
import { POSTS, UPDATE_POST, DELETE_POST } from '../utils/Queries';

const Calendar = ({ canEdit }) => {
  const numWeeks = 20;
  const [date, setDate] = useState(today());
  const [[start, end], setRange] = useState(range(undefined, numWeeks));
  const [state, setState] = useState([]);
  const [scroll, setScroll] = useState(0);

  const { loading, /*error,*/ data } = useQuery(POSTS, { variables: { start, end }, refetchAfterMutations: [ UPDATE_POST, DELETE_POST ]})

  const [updatePost] = useMutation(UPDATE_POST);
  const [deletePost] = useMutation(DELETE_POST);

  const wrapper = useRef(null);
  const scrollRef = useRef(null);

  // Load calendar from today
  useEffect(() => {
    if(loading) return;
    if(!data?.posts) return;
    const { posts } = data;
    const dates = make(start, end);
    const days = hydrate(dates, posts);
    setState(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useLayoutEffect(() => {
    if(loading || state.length === 0) return;
    if(!scrollRef.current) return;

    if(scroll === 0) return scrollRef.current.scrollIntoView(false); // today or next
    if(scroll === -1) wrapper.current.scrollTo({ top: scrollRef.current.offsetTop, behavior: 'instant' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const onDrop = (date, item) => {
    setScroll(1);
    updatePost({ variables: { input: { ...item, date } }});
  }

  const onTrash = (id) => {
    setScroll(1);
    deletePost({ variables: { id }});
  }

  const onPrevious = () => {
    setDate(start);
    setScroll(-1);
    setRange(([start, end]) => {
      const range = [start.subtract(20, 'week'), start.add(10, 'week').subtract(1, 'day')];
      return range;
    })
  }

  const onNext = () => {
    setDate(end);
    setScroll(1);
    setRange(([start, end]) => {
      const range = [end.subtract(20, 'week').add(1, 'day'), end.add(10, 'week')];
      return range;
    })
  }

  return (
    <>
      <div className={style.wrapper} ref={wrapper}>
        { loading && <Loader/> }
        <div className={style.load}><Button className="naked" label="Load more" onClick={onPrevious}/></div>
        <div className={style.calendar}>
          {
            state.map((day, i) => {
                // set first element to track
                //if(i === 0) return <Day key={day.date.format('DD-MM-YYYY')} {...{...day, onDrop, canEdit, innerRef: el => onLoad(startRef, el, 'previous')}}/>
                // set last element to track
                //if(i === state.length - 1) return <Day key={day.date.format('DD-MM-YYYY')} {...{...day, onDrop, canEdit, innerRef: el => onLoad(endRef, el, 'next')}}/>
                // set scroll to element
                if(day.date.isSame(date, 'day')) return <Day key={day.date.format('DD-MM-YYYY')} {...{...day, onDrop, canEdit, innerRef: el => scrollRef.current = el}}/>
                // set other elements
                return <Day key={day.date.format('DD-MM-YYYY')} {...{...day, onDrop, canEdit}}/>
            })
          }
        </div>
        <div className={style.load}><Button className="naked" label="Load more" onClick={onNext}/></div>
        { loading && <Loader/> }
      </div>
      <ToolBar {...{ onTrash }}/>
    </>
  )
}

export default Calendar;
