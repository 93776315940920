import style from './Auth.module.css';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'graphql-hooks';

import { UPDATE_USER } from '../utils/Queries';
import { useSession } from '../utils/useSession';

import Button from '../components/Button';
import Loader from '../components/Loader';

const Register = () => {
  const navigate = useNavigate();
  const { user, setUser } = useSession();
  const [username, setUsername] = useState('');
  const [err, setErr] = useState(false);

  const [updateUser, { data, loading }] = useMutation(UPDATE_USER);

  useEffect(() => {
      if(!user) navigate('/');
  }, [user, navigate])

  useEffect(() => {
    if(data?.updateUser) {
      setUser(data.updateUser);
      navigate(`/${data.updateUser.username}`);
    }
  }, [data, setUser, navigate])

  const change = e => {
    const usernameRegex = /^[a-zA-Z0-9_]*$/;
    let value = e.target.value;
    setUsername(value);
    if(!usernameRegex.test(value)) return setErr(true);
    setErr(false);
  }

  const submit  = () => {
    if(username === '' || err) return;
    updateUser({ variables: { input: { username } }});
  }

  return (<>
    <main>
      <Button className="back" onClick={() => navigate(-1)} />
      { loading && <Loader/> }
      {
        !loading &&
        <section>
          <h1>Choose your username</h1>
          <span className={`${style.arobase} ${err ? `${style.hasError}` : ''}`}>@</span>
          <input type="text" value={username} onChange={change} className={`${style.username} ${err ? `${style.hasError}` : ''}` } maxLength="12" />
          <span className={`${style.error} ${err ? style.hasError : ''}`}>Invalid username</span>
        </section>
      }
      { !loading && <Button className="cta" onClick={submit} label="Next →" /> }

    </main>
    </>)
}

export default Register;
