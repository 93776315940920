import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import isToday from 'dayjs/plugin/isToday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';

dayjs.extend(localeData)
dayjs.extend(isoWeek);
dayjs.extend(isToday)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)

export const today = () => dayjs();

export const range = (date = dayjs(), numberOfWeeks) => {
  const start = date.subtract(7 * numberOfWeeks - 14, 'day').startOf('isoWeek');
  const end = start.add(numberOfWeeks, 'week').endOf('isoWeek');

  return [start, end];
}

export const make = (start, end) => {
  const dates = [];
  let date = start;

  while (date.isSameOrBefore(end)) {
    dates.push(date);
    date = date.add(1, 'day');
  }

  return dates;
}

export const hydrate = (dates, emojis = []) => {
  // loop throught dates add emojis that filter for date
  return dates.map(date => ({
    date: date,
    emojis: emojis.filter(e => dayjs(e.date).isSame(date, 'day')),
  }))
}

export const weekdays = [...dayjs.weekdaysShort().slice(1), dayjs.weekdaysShort().shift()]
