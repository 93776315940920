import style from './Day.module.css';

import { useDrop } from 'react-dnd';

import Emoji from '../components/Emoji';

const Day = ({date, emojis, onDrop, canEdit, innerRef }) => {
  // const year = date.date() === 1 && date.month() === 0 ? date.format('YYYY') : undefined;
  const month = date.date() === 1 ? date.format('MMMM YY') : undefined;
  const today = date.isToday() ? { color: 'var(--cta-color)', fontWeight: 700} : undefined;

  const handleDrop = (item) => {
      onDrop(date, item);
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'EMOJI',
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  return (
    <div ref={innerRef} className={`${style.day} ${isOver ? style.over : '' }`}>
      {month && <span className={style.month}>{month}</span>}
      <div ref={drop}  className={style.dropzone}>{
        emojis
          .sort((a,b) => a.emoji > b.emoji ? 1 : -1)
          .map(emoji => <Emoji key={emoji.id} {...emoji} editable={canEdit}/>)
      }</div>
      <div id={date.format('DD-MM-YY')} className={style.date} style={today}>{date.format('DD')}</div>
    </div>
  )
}

export default Day;
