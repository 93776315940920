import { useState, useEffect, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useManualQuery, ClientContext } from 'graphql-hooks';
import { ALIVE } from '../utils/Queries';

const Session = createContext();
const { Provider } = Session;

const SessionProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const client = useContext(ClientContext);
  const [alive] = useManualQuery(ALIVE);
  const navigate = useNavigate();


  const localUser = JSON.parse(localStorage.getItem('user') || null);
   if(localUser?.token) {
     client.setHeader('Authorization', `Bearer ${localUser.token}`);
   };

   const logout = () => {
     setUser(undefined);
     localStorage.removeItem('user');
     client.removeHeader('Authorization');
     return navigate('/');
   }

  const isAlive = async _ => {
    const { data } = await alive();

    // if logged go to profile or home
    if(data.alive) {
      setUser(data.alive);
      return navigate(`/${data.alive.username}`);
    }
    // or logout
    logout();
   }

  useEffect(() => {
    if(localUser) isAlive();
  },[])

  useEffect(() => {
    if(user) {
       localStorage.setItem('user', JSON.stringify(user));
       client.setHeader('Authorization', `Bearer ${user.token}`);
    }
  }, [user])

  const refresh = async _  => isAlive();

  return <Provider value={{ user, setUser, refresh, logout }}>{children}</Provider>
}

export const useSession = () => useContext(Session);
export default SessionProvider;
