import style from './Avatar.module.css';

import { useMutation } from 'graphql-hooks';
import { useDrop } from 'react-dnd';

import { UPDATE_USER } from '../utils/Queries';
import { useSession } from '../utils/useSession';


const Avatar = ({ avatar }) => {

  const [updateUser] = useMutation(UPDATE_USER);
  const { refresh } = useSession();

  const handleDrop = async ({ emoji }) => {
        await updateUser({ variables: { input: { emoji } }});
        refresh();
    }

    const [{ isOver }, drop] = useDrop(() => ({
      accept: 'EMOJI',
      drop: handleDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }))

  return (
    <div ref={drop} className={`${style.avatar} ${isOver ? style.over : '' }`}>
      { avatar }
    </div>
  )
}

export default Avatar;
