import style from './ToolBar.module.css';

import { useState } from 'react';
import { useDrop } from 'react-dnd';
import Picker from 'emoji-picker-react';

import { weekdays } from '../utils/Engine';
import Emoji from './Emoji';

const ToolBar = ({onTrash}) => {
  const [state, setState] = useState(['💪', '🍺', '🍷', '🥛', '🚭']);
  const [emoji, setEmoji] = useState('🆕');
  const [show, hide] = useState(false);

  const handleDrop = (item) => {
      onTrash(item.id);
  }
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'EMOJI',
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  const onPick = (_, {emoji}) => {
    setEmoji(emoji);
    hide(prev => !prev);
  }

  const toggle = () => {
    hide(prev => !prev);
  }

  return (<>
    <div className={`${style.picker} ${show ? style.show : style.hide}`}>
      <Picker onEmojiClick={onPick} pickerStyle={{ width: '100%', height: '100%' }} native="true" disableAutoFocus="true"/>
    </div>
    <div className={style.toolbox}>
      { weekdays.map(d => <div className={style.weekdays} key={d}>{d}</div>) }
      <div onClick={toggle}><Emoji {...{emoji}}/></div>
      { state.map((emoji,i) => <Emoji key={i} {...{emoji}}></Emoji>) }
      <div ref={drop} className={`${style.trash} ${isOver ? style.trashOver : ''}`}>🗑</div>
    </div>
  </>)
}

export default ToolBar;
