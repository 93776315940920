import style from './Emoji.module.css';

import { useDrag } from 'react-dnd';

const Emoji = ({id, emoji, editable = true}) => {
  const [collected, drag] = useDrag(() => ({
    type: 'EMOJI',
    item: { id, emoji },
    canDrag: editable,
  }), [emoji, editable])

  return <div ref={drag}><div className={`${style.small} ${editable ? style.editable : ''}`}>{emoji}</div></div>;
}

export default Emoji;
