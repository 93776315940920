import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GraphQLClient, ClientContext } from 'graphql-hooks';

import Session from './utils/useSession';
import Home from './views/Home';
import Auth from './views/Auth';
import Register from './views/Register';
import User from './views/User';

const client = new GraphQLClient({
  url: '/graphql',
  logErrors: false,
})

const App = () => {
  return (
    <BrowserRouter>
      <ClientContext.Provider value={client}>
        <Session>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/auth" element={<Auth/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/:username" element={<User/>} />
          </Routes>
        </Session>
      </ClientContext.Provider>
    </BrowserRouter>
  )
}

export default App;
