import style from './Auth.module.css';
import 'react-phone-number-input/style.css';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { useManualQuery } from 'graphql-hooks';

import { AUTH } from '../utils/Queries';
import { useSession } from '../utils/useSession';

import Button from '../components/Button';
import Loader from '../components/Loader';

const Auth = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [code, setCode] = useState('');
  const [auth, { data, loading, error }] = useManualQuery(AUTH);
  const [stage, setStage] = useState(0);
  const { user, setUser } = useSession();

  useEffect(() => {
    if(!data) return;

    const { phone, username, token } = data.auth;

    if(phone && !token) return setStage(1);
    if(token) setUser(data.auth);
    if(!username) return navigate('/register');
  },[data, setUser, navigate]);

  useEffect(() => {
    if(user) navigate(`/${user.username}`);
  }, [user, navigate])

  const submit = async () => {
    if(!phone) return;
    auth({ variables: { phone, code } });
  };

  return (<>
      <main>
        <Button className="back" onClick={() => navigate(-1)} />
        { loading && <Loader /> }
        {
          stage === 0 && !loading &&
          <section>
            <h1>Enter your phone #</h1>
            <PhoneInput value={phone} onChange={setPhone} className={error ? 'hasError' : ''}/>
            <span className={`${style.error} ${error ? style.hasError : ''}`}>Invalid phone #</span>
            <p className="small center">By entering your number, you're agreeing to our Terms of Service and Privacy Policy. Thanks!</p>
          </section>
        }
        {
          stage === 1 && !loading &&
          <section>
            <h1>Enter the code we just texted you</h1>
            <input type="text" name="token" id="token" onChange={e => setCode(e.target.value)} value={code} className={style.token} inputMode="numeric" pattern="[0-9]*" autoComplete="one-time-code" maxLength="6" placeholder="••••••"/>
            <span className={`${style.error} ${error ? style.hasError : ''}`}>Invalid code #</span>
            <p className="small center">Didn't receive it? Go back to resend</p>
          </section>
        }
        { !loading && <Button className="cta" onClick={submit} label="Next →" /> }
      </main>
    </>)
}

export default Auth;
