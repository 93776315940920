import style from './User.module.css';

import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import Calendar from '../calendar/Calendar';
import Avatar from '../components/Avatar';
import Button from '../components/Button';

import { useSession } from '../utils/useSession';

const isTouch = () => {
	return ( 'ontouchstart' in window ) ||
		( navigator.maxTouchPoints > 0 ) ||
		( navigator.msMaxTouchPoints > 0 );
}

const Header = ({ canEdit, setEdit }) => {
		const { user = {}, logout } = useSession();

		return (
		<div className={style.user}>
			<Avatar avatar={user.emoji}/>
			<h1 className={style.username}>@{user.username}</h1>
			<div className={style.count}><span>{user.count}</span> Posts</div>
			<Button className={canEdit ? 'follow-active' : 'follow'} label="Edit" onClick={() => setEdit(toggle => !toggle)} />
			<Button className="follow" label="Logout" onClick={() => logout()} />

	</div>)
}

const Profile = () => {
	const [canEdit, setEdit] = useState(false);

  return (
    <DndProvider backend={isTouch() ? TouchBackend : HTML5Backend} >
      <div className={style.profile}>
				<Header {...{ canEdit, setEdit }}/>
        <Calendar className={style.calendar} {...{ canEdit }} isTouch={isTouch()}/>
      </div>
		</DndProvider>
  )
}

export default Profile;
